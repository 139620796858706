import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getIndexes, getPlaceOrderIndexes } from "../../services/admin.service";

const initialState = {
  indexes: [],
  placeOrderIndexes: [],
};

  export const getIndexesThunk = createAsyncThunk("indexes", async () => {
    const { success, result } = await getIndexes();
    
    if (success) return result;
    return initialState.indexes;
  });

export const getPlaceOrderIndexThunk = createAsyncThunk(
  "placeOrderIndex", async () => {
    const { success, result } = await getPlaceOrderIndexes();
    if (success) return result;
    return initialState?.placeOrderIndexes;
  }
);

const indexSlice = createSlice({
  name: "indexes",
  initialState,
  reducers: {
    setupIndexes: (state, action) => {
      state.indexes = action.payload;
      return state;
    },
    resetIndexes: (state) => {
      state.indexes = initialState.indexes;
      state.placeOrderIndexes = initialState.placeOrderIndexes;
      return state;
    },
  },
  extraReducers: (build) => {
    build.addCase(getIndexesThunk.fulfilled, (state, action) => {
      state.indexes = action.payload;
      return state;
    })
    .addCase(getPlaceOrderIndexThunk.fulfilled, (state, action) => {
      state.placeOrderIndexes = action.payload;
      return state;
    });
  },
});

export const { resetIndexes, setupIndexes } = indexSlice.actions;
export default indexSlice.reducer;
