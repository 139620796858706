import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { getPositionsData, newGroup } from "../../services/admin.service";
import { useDispatch } from "react-redux";
import { openToast } from "../../redux/slice/toastSlice";
import { FaHome } from "react-icons/fa";
import CustomLoader from "../../components/loader/CustomLoader";
import * as style from "../client/addClient/style";

const GroupCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [currentContracts, setCurrentContracts] = useState({});
  const { state } = useLocation();
  const { client } = state || {};
  const { clientId } = useParams();

  const fetchPositionsData = useCallback(async () => {
    setLoading(true);
    try {
      const { success, result, message } = await getPositionsData();
      if (success) {
        setPositions(result);

        const contracts = {};
        result.forEach((client) => {
          client.positions.forEach((position) => {
            if (position.groups) {
              position.groups.forEach((group) => {
                group.positions.forEach((contractPosition) => {
                  contracts[contractPosition.contract] = {
                    selected: false,
                    quantity: contractPosition.quantity || 0,
                  };
                });
              });
            }
          });
        });
        setCurrentContracts(contracts);
      } else {
        dispatch(
          openToast({
            message: message || "Failed to fetch data",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        openToast({
          message: error.message || "Some error occurred",
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchPositionsData();
  }, [fetchPositionsData]);

  const handleInputChange = (e, contract) => {
    const { value, checked, type } = e.target;
    setCurrentContracts({
      ...currentContracts,
      [contract]: {
        ...currentContracts[contract],
        [type === "checkbox" ? "selected" : "quantity"]:
          type === "checkbox" ? checked : value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      group_name: categoryName,
      client_id: clientId,
      group_positions: Object.keys(currentContracts)
        .filter((key) => currentContracts[key].selected)
        .map((key) => ({
          contract: key,
          quantity: currentContracts[key].quantity,
        })),
    };

    try {
      const response = await newGroup(formData);

      if (response?.success) {
        navigate("/dashboard");
      } else {
        dispatch(
          openToast({
            message: response?.message || "Something went wrong",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        openToast({
          message: error.message || "Something went wrong",
          type: "error",
        })
      );
    }
  };

  const contracts = [];
  client?.positions?.forEach((position) => {
    position.groups?.forEach((group) => {
      if (group?.group_name === "Default") {
        group.positions?.forEach((contractPosition) => {
          if (contractPosition.quantity) {
            contracts.push({
              contract: contractPosition.contract,
              quantity: contractPosition.quantity || 0,
            });
          }
        });
      }
    });
  });

  return (
    <div
      className={`ps-container all-normal-text container ${
        loading && "ps-load"
      }`}
    >
      {loading ? (
        <style.LoaderWrapper>
          <CustomLoader />
        </style.LoaderWrapper>
      ) : (
        <div>
          <div className="mt-3 mb-3 d-flex justify-content-between align-items-center">
            <div style={{ fontSize: "16px" }}>Group</div>
            <button
              className="flex items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
              onClick={() => navigate("/dashboard")}
            >
              <FaHome className="mr-1 align-middle" />
              Home
            </button>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} controlId="formCategoryName">
              <Form.Label column sm="2">
                Group Name
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  placeholder="Enter Strategy name"
                  className="mb-2 all-normal-text"
                  name="categoryName"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formCurrentContracts">
              <Form.Label column sm="2">
                Current Contracts
              </Form.Label>
              <Col sm="10">
                {contracts.map(({ contract, quantity }, index) => (
                  <div key={index} className="flex items-center mb-1">
                    <div className="cursor-pointer check">
                      <Form.Check
                        type="checkbox"
                        label={contract}
                        name={contract}
                        className="cursor-pointer"
                        checked={currentContracts[contract]?.selected || false}
                        onChange={(e) => handleInputChange(e, contract)}
                      />
                    </div>
                    <div>
                      <Form.Control
                        type="number"
                        name={`${contract}-quantity`}
                        disabled={true}
                        value={currentContracts[contract]?.quantity || 0}
                        // disabled
                        onChange={(e) => handleInputChange(e, contract)}
                        min={0}
                        className="ml-2 all-normal-text"
                      />
                    </div>
                  </div>
                ))}
              </Col>
            </Form.Group>
            <div className="flex flex-row-reverse w-full">
              <button
                className="flex items-center h-8 px-2 text-white bg-gray-900 text-base border-none cursor-pointer focus:outline-none button-style"
                type="submit"
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default GroupCategory;
